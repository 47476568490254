import React from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { DemoForm, DemoFormBorder, DemoFormProps } from './DemoForm';
import { RodoLink } from './RodoLink';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

interface SelectableDemoForm {
  title: string;
  src: DemoFormProps['src'];
  width: DemoFormProps['width'];
  height: DemoFormProps['height'];
}

export interface SelectableDemoFormsProps {
  forms: SelectableDemoForm[];
}

const DemoFormPlaceholder: React.FC<{
  height: DemoFormProps['height'];
  width: DemoFormProps['width'];
}> = ({ height, width }) => (
  <Stack
    height={height}
    width={width}
    direction='column'
    alignItems='center'
    // Padding eksperymentalnie zrównany z SendGridowym.
    p='18px'
    paddingTop={0.5}
  >
    <ArrowUpwardIcon sx={{ fontSize: '60pt' }} />
    <Typography variant='body2' mb={1}>
      Wybierz swoją specjalizację aby uzyskać dostęp do wersji demo najlepiej ilustrującej możliwości systemu Mediporta
      w Twojej działalności. Dostęp prześlemy e-mailem po wypełnieniu formularza.
    </Typography>
    <Typography variant='body2' mb={1}>
      Administrator danych osobowych jest Mediporta Sp. z o.o. z siedzibą w Poznaniu przy ul. Władysława Reymonta 35.
      Dane podane w formularzu DEMO będą przetwarzane w celu otrzymania wiadomości email z dostępem do wersji
      demonstracyjnej systemu, a także późniejszego kontaktu biznesowego w tej sprawie. Podanie danych jest dobrowolne,
      ale niezbędne, aby otrzymać dostęp do DEMO.
    </Typography>
    <Typography variant='body2' mb={1}>
      Przetwarzanie danych odbywa się za Twoją zgodą, którą możesz cofnąć w dowolnym momencie. Dane te będą przetwarzane
      do czasu wycofania zgody.
    </Typography>
    <Typography variant='body2' mb={1}>
      Szczegóły dotyczące przetwarzania Twoich danych osobowych dostępne są w ramach zakładki <RodoLink>RODO</RodoLink>.
    </Typography>
  </Stack>
);

export default (props: SelectableDemoFormsProps) => {
  const [value, setValue] = React.useState<string>('-');

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
  };

  return (
    <DemoFormBorder>
      <FormControl fullWidth>
        <Select
          variant='standard'
          value={value}
          onChange={handleChange}
          // https://stackoverflow.com/questions/71745457/react-materialui-menu-bounces-layout-15px-to-the-left-in-the-presence-of-a-ver
          // Rozwiązanie działa, mimo że TS podkreśla prop jako nieistniejący.
          MenuProps={{
            disableScrollLock: true
          }}
          inputProps={{
            'aria-label': 'Wybierz specjalizację'
          }}
          sx={{
            '.MuiSelect-select': {
              padding: '16px'
            },
            '.MuiSelect-icon': {
              right: '16px'
            }
          }}
        >
          <MenuItem value='-'>(Twoja specjalizacja)</MenuItem>
          {props.forms.map((form, index) => (
            <MenuItem key={index} value={index}>
              {form.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {value === '-' ? <DemoFormPlaceholder {...props.forms[0]} /> : <DemoForm {...props.forms[Number(value)]} />}
    </DemoFormBorder>
  );
};

import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Grid, Typography } from '@mui/material';
import { Button } from 'gatsby-material-ui-components';
import { StaticImage } from 'gatsby-plugin-image';
import StandardFrame from '../components/StandardFrame';
import PageSection from '../components/Sections/PageSection';
import PageSectionWithImage from '../components/Sections/PageSectionWithImage';
import PageSectionTitle from '../components/Sections/PageSectionTitle';
import PatientFlowHero from '../components/Heros/PatientFlowHero';
import AdvantageSectionContent from '../components/Sections/Advantages/AdvantageSectionContent';
import { IFrameWithWidthBasedAspectRatio } from '../components/IFrameWithAspectRatio';
import TestimonialCarousel from '../components/TestimonialCarousel';
import PageSectionActionButton from '../components/Sections/PageSectionActionButton';
import DemoFormSectionContent from '../components/Sections/DemoFormSectionContent';
import FaqSectionContent from '../components/Sections/FaqSectionContent';
import TalkToSalesAdvisorSection from '../sections/landing/porozmawiaj-z-doradca-handlowym';
import { ContactForm } from '../components/Sections/ContactForm';

export const pageQuery = graphql`
  query Index {
    testimonial: allMarkdownRemark(filter: { childTestimonial: { id: { ne: null } } }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            name
            captions
            photo {
              childImageSharp {
                gatsbyImageData(
                  layout: FIXED
                  width: 250
                  quality: 95
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          html
        }
      }
    }
    faq: allMarkdownRemark(
      filter: { childFaq: { id: { ne: null } }, frontmatter: { tags: { in: ["general"] } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          frontmatter {
            question
            tags
          }
          html
        }
      }
    }
    advantage: allPrzewagiYaml(filter: { tags: { in: ["general"] } }) {
      edges {
        node {
          id
          title
          description
        }
      }
    }
    persona: allMarkdownRemark(
      filter: { childPersona: { id: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            demo_form
            demo_form_width
            demo_form_height
            order
          }
        }
      }
    }
  }
`;

export default ({ data: { testimonial, persona, advantage, faq } }: PageProps<Queries.IndexQuery>) => {
  return (
    <>
      <Helmet title='Oprogramowanie dla gabinetów i przychodni' />
      <StandardFrame>
        <PageSectionWithImage
          maxWidth='lg'
          color='white'
          bgimage={
            <StaticImage
              style={{ height: '100%' }}
              layout='fullWidth'
              quality={95}
              src='../images/hero_convertible.jpg'
              alt=''
            />
          }
        >
          <Grid container columnSpacing={6}>
            <Grid item xs={12} md={6}>
              <Typography variant='h2' component='h1' textTransform='uppercase' mb={3}>
                Chmurowe oprogramowanie
                <br />
                dla gabinetów i przychodni
              </Typography>
              <Typography variant='h4' component='p' mb={3}>
                Mediporta to system ułatwiający zarządzanie placówkami medycznymi na wszystkich etapach obsługi
                pacjenta: od umówienia wizyty i rejrestracji, przez prowadzenie dokumentacji medycznej (w tym EDM), na
                rozliczeniach NFZ i prywatnych kończąc.
              </Typography>
              <Button to='#demo' sx={{ minWidth: '60%' }}>
                Sprawdź jak Mediporta może wyglądać u Ciebie
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <IFrameWithWidthBasedAspectRatio
                width='100%'
                ratio={9 / 16}
                iframeProps={{
                  src: 'https://www.youtube.com/embed/atBgW_D3em0',
                  title: 'Mediporta - Prosta jak jazda na rowerze',
                  frameBorder: 0,
                  allowFullScreen: true
                }}
              />
            </Grid>
          </Grid>
        </PageSectionWithImage>

        <PageSection bgcolor='tertiary.main' maxWidth='lg'>
          <PageSectionTitle>Dlaczego warto wybrać Mediportę</PageSectionTitle>
          <AdvantageSectionContent
            showExpandAdvantagesButton
            items={advantage.edges.map(({ node }) => ({
              id: node!.id!,
              title: node!.title!,
              description: node!.description!
            }))}
            spacing={3}
          />
        </PageSection>

        <PatientFlowHero /* ~ bgcolor="primary.main" */>
          <PageSectionActionButton to='/funkcje'>Poznaj wszystkie funkcje</PageSectionActionButton>
        </PatientFlowHero>

        <PageSection id='demo' maxWidth='lg'>
          <PageSectionTitle>Sprawdź jak Mediporta może wyglądać u Ciebie</PageSectionTitle>
          <Helmet>
            <meta httpEquiv='Content-Security-Policy' content='upgrade-insecure-requests' />
          </Helmet>
          <DemoFormSectionContent
            // https://stackoverflow.com/questions/54838185/iframe-loads-relative-url-as-http-even-though-the-parent-url-was-loaded-with-htt
            forms={persona.edges.map(({ node }) => ({
              title: node.frontmatter!.title!,
              src: node.frontmatter?.demo_form,
              width: node.frontmatter!.demo_form_width!,
              height: node.frontmatter!.demo_form_height!
            }))}
          />
        </PageSection>

        <PageSection bgcolor='primary.main' color='white' maxWidth='lg'>
          <PageSectionTitle>Zobacz, co mówią o Mediporcie nasi klienci</PageSectionTitle>
          <TestimonialCarousel
            items={testimonial.edges.map(({ node }) => ({
              name: node!.frontmatter!.name!,
              captions: node!.frontmatter!.captions!.map((caption) => caption!),
              photo: node!.frontmatter!.photo?.childImageSharp?.gatsbyImageData,
              html: node!.html!
            }))}
          />
        </PageSection>

        <PageSection maxWidth='lg' bgcolor='tertiary.main'>
          <PageSectionTitle>Najczęściej zadawane pytania</PageSectionTitle>
          <FaqSectionContent
            items={faq.edges.map(({ node }) => ({
              id: node!.id,
              question: node!.frontmatter!.question!,
              answerHTML: node!.html!
            }))}
          />
        </PageSection>

        <TalkToSalesAdvisorSection />
        <ContactForm bgcolor={'tertiary.main'} />
      </StandardFrame>
    </>
  );
};
